'use client'
import { ContactForm } from "@general/components/client_side/ContactForm";
import Image from 'next/image'
import logoShort from '@general/assets/logo_short.svg'
import { useSelector } from "react-redux";
import { RootState } from "@general/redux/store";
import { useTranslation } from "@general/i18n/client";

import '@general/styles/components/footer.scss'

export function Footer({title,description}: {title:string, description: string}): JSX.Element {
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(selectedLang, 'common')
    return <div className='footer py-5'>
        <div className='container'>
            <h3 className="h1 text-center text-white text-uppercase">{title}</h3>
            <p className="h4 text-center text-white pb-5">{description}</p>
            <ContactForm />
        </div>
        <hr className="my-5 text-light"/>
        <div className="container">
            <div className="row">
                <div className="col-md-4 text-white-50 contact_block">
                <Image 
                    className="footer_logo" 
                    alt={t('Logo WM Web Agency')}
                    src={logoShort.src} 
                    width={70}
                    height={70}
                    />
                    <ul className="contact_list">
                        <li>{t('Location: city Bucha, Ukraine')}</li>
                        <li>{t('Email: ')}<a href="mailto:office@wmweb.agency">office@wmweb.agency</a></li>
                        <li>{t('Phone: ')}<a href="tel:+380981557713">+380981557713</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
}